import { kebabCase } from 'lodash-es'

export const getSingleTypeTitle = (uid: string) => {
  if (!uid) return null

  const mainStore = useMainStore()
  const appVariant = getAppVariant()

  const titles = mainStore.singleTypeTitles

  if (!titles) return null

  return titles?.find((title) => {
    if (appVariant === 'interim' && uid === 'home-page') {
      return kebabCase(title?.uid) === 'interim-home-page'
    } else if (appVariant === 'interim' && uid === 'newsletter-page') {
      return kebabCase(title?.uid) === 'interim-newsletter-page'
    } else if (appVariant === 'interim' && uid === 'news-page') {
      return kebabCase(title?.uid) === 'interim-news-page'
    } else if (appVariant === 'interim' && uid === 'press-page') {
      return kebabCase(title?.uid) === 'interim-press-page'
    } else {
      return kebabCase(title?.uid) === uid
    }
  })?.title || null
}
